import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { IDialogActions, IDialogProps } from './types';

export const CustomDialog: React.FC<IDialogProps & IDialogActions> = ({
  headerMessage,
  content,
  open,
  submitLoading,
  setDialogueOpen,
  handleConfirm,
}) => {
  const handleClose = () => {
    setDialogueOpen(false);
  };

  const onClose = (event: any, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    setDialogueOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{headerMessage}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ whiteSpace: 'pre-line' }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleClose}
          color="error"
          variant="contained"
          disabled={submitLoading}
        >
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          autoFocus
          color="success"
          variant="contained"
          disabled={submitLoading}
        >
          {submitLoading ? 'Submitting...' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
