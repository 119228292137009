import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { getUserData } from '../../utils/userData';

interface IProtectedRouteProps {
  children?: any;
}

export const ProtectedRoute: FC<IProtectedRouteProps> = ({ children }) => {
  const userData = getUserData();

  if (!userData) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
