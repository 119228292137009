import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import { theme } from './theme';
import './dayjs';
import ErrorBoundary from './components/ErrorBoundary';
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

function SnackbarCloseButton({ snackbarKey }: { snackbarKey: SnackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      onClick={() => closeSnackbar(snackbarKey)}
      sx={{ color: 'inherit' }}
    >
      <CloseIcon />
    </IconButton>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <SnackbarProvider
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={3000}
            maxSnack={3}
            action={(snackbarKey) => (
              <SnackbarCloseButton snackbarKey={snackbarKey} />
            )}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CssBaseline />
              <App />
            </LocalizationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
