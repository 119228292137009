import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Component, ErrorInfo, ReactNode } from 'react';
import { CENTERED_STYLE } from '../constants/styles';

type ErrorBoundaryProps = {
  children: ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError = () => {
    return { hasError: true };
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render fallback UI
      return (
        <Box sx={CENTERED_STYLE}>
          <Typography>
            Something terrible happened, please restart the page
          </Typography>
        </Box>
      );
    }
    // Render children components
    return this.props.children;
  }
}

export default ErrorBoundary;
