import { USER_DATA } from '../constants/storageParams';

export const getUserData = () => {
  try {
    return Boolean(localStorage.getItem(USER_DATA))
      ? JSON.parse(localStorage.getItem(USER_DATA) ?? 'null')
      : JSON.parse(sessionStorage.getItem(USER_DATA) ?? 'null');
  } catch (e) {
    return e;
  }
};

export const removeUserData = (): void => {
  localStorage.getItem(USER_DATA)
    ? localStorage.removeItem(USER_DATA)
    : sessionStorage.removeItem(USER_DATA);
};
