import { createTheme } from '@mui/material/styles';
import { COLORS } from './constants/colors';
import { APPBAR_WIDTH } from './constants/themeParameters';

export const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.primary,
    },
    background: {
      default: COLORS.background,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        positionStatic: {
          height: `${APPBAR_WIDTH}px`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '8px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          backgroundColor: COLORS.primary,
          color: COLORS.white,
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          background: COLORS.background,
        },
      },
    },
  },
});
