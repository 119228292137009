import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';

import { getUserData } from '../utils/userData';
import { FormControlLabel, Checkbox } from '@mui/material';
import { USER_DATA } from '../constants/storageParams';
import { BASE_API } from '../constants/api';

export const Login = () => {
  const userData = getUserData();
  const navigate = useNavigate();
  const [error, setError] = useState<string>('');
  const [user, setUser] = useState({ email: '', password: '' });
  const [stayLoggedIn, setStayLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    if (userData) {
      navigate('/', { replace: true });
    }
  }, [userData]);

  const validateForm = () => {
    return user.email.length > 0 && user.password.length > 0;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStayLoggedIn(event.target.checked);
  };

  const login = async (event: any) => {
    event.preventDefault();
    try {
      const { data }: any = await axios.post(
        `${BASE_API}/login`,
        user
      );

      if (data && data.email && data.name) {
        stayLoggedIn
          ? localStorage.setItem(USER_DATA, JSON.stringify(data))
          : sessionStorage.setItem(USER_DATA, JSON.stringify(data));
      }

      navigate('/');
    } catch (error) {
      setError('Invalid Username or Password');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={login} sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
            error={!!error}
            helperText={error ?? ''}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={user.password}
            onChange={(e) => setUser({ ...user, password: e.target.value })}
            error={!!error}
            helperText={error ?? ''}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={stayLoggedIn}
                value={stayLoggedIn}
                color="primary"
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={login}
            disabled={!validateForm()}
          >
            Sign In
          </Button>
           {/* <Grid container>
            <Grid item>
              <Link to="/signup">Don't have an account? Sign Up</Link>
            </Grid
          </Grid>  */}
        </Box>
      </Box>
    </Container>
  );
};
