import dayjs from 'dayjs';
import { IRowData } from './types';

export const theads = [
  'Site Location',
  'Candidate Name',
  'Date',
  'Start Time',
  'End Time',
  'Lunch',
  'Total Hours',
  'Notes',
];

export const lunchOptions = [0, 0.5, 1];

export const getInitialEmptyData = (): IRowData => {
  const startTime = dayjs()
    .set('hour', 6)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  const endTime = dayjs()
    .set('hour', 14)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0);
  return {
    candidate: { name: '', inputValue: '' },
    siteLocation: '',
    startDate: dayjs(),
    startTime,
    endTime,
    lunch: lunchOptions[0],
    totalHours: endTime.diff(startTime, 'hour'),
    notes: '',
    id: crypto.randomUUID(),
  };
};

export const w200 = { width: '200px' };
