import { useState, useCallback, useEffect } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { CustomDialog } from '../Dialog/Dialog';
import dayjs from 'dayjs';
import axios from 'axios';
import { useSnackbar } from 'notistack';

import { getInitialEmptyData, theads, lunchOptions, w200 } from './constants';
import { IRowData, ICandidate, ILocationData } from './types';
import { Navbar } from '../Navbar';
import { Loader } from '../Loader';
import { getUserData } from '../../utils/userData';
import { BASE_API } from '../../constants/api';
import { FIELD_IS_REQUIRED } from '../../constants/errorMessages';

export const Timesheet = () => {
  const { enqueueSnackbar } = useSnackbar();
  const userData = getUserData();
  const [rows, setRows] = useState<Array<IRowData>>([getInitialEmptyData()]);
  const [dialogueOpen, setDialogueOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [locationData, setLocationData] = useState<Partial<ILocationData>>({});
  const [contentMessage, setContentMessage] = useState<string>('');
  const [errors, setErrors] = useState<Array<any>>([]);

  const getLocations = async () => {
    try {
      const { data } = await axios(`${BASE_API}/locations/${userData.id}`);

      if (typeof data === 'object' && Object.keys(data).length > 0) {
        setLocationData(data);
      } else {
        setLocationData({});
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getLocations();
    try {
      const savedTemplate = JSON.parse(
        localStorage.getItem('saved_timesheet') ?? '{}'
      );
      if (
        savedTemplate.currentUserId &&
        savedTemplate.currentUserId === userData.id
      ) {
        const updatedTimesheet = (savedTemplate.timesheet ?? []).map(
          (item: IRowData) => {
            return {
              ...item,
              startDate: dayjs(item.startDate),
              startTime: dayjs(item.startTime),
              endTime: dayjs(item.endTime),
            };
          }
        );
        setRows(updatedTimesheet);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleValidate = () => {
    const localErrors: Array<any> = [];
    let formIsValid = true;

    rows.forEach((row, i) => {
      if (!row.candidate.name) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].candidateName = FIELD_IS_REQUIRED;
      }
      if (!row.siteLocation) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].siteLocation = FIELD_IS_REQUIRED;
      }
      if (!row.startDate) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].startDate = FIELD_IS_REQUIRED;
      }
      if (!row.startTime) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].startTime = FIELD_IS_REQUIRED;
      }
      if (!row.endTime) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].endTime = FIELD_IS_REQUIRED;
      }
      if (row.totalHours < 0) {
        formIsValid = false;
        localErrors[i] = {};
        localErrors[i].totalHours = 'Cannot be negative number';
      }
    });
    setErrors(localErrors);
    return formIsValid;
  };
  const handleConfirm = async () => {
    const data: Array<any> = rows.map((row: IRowData) => {
      return {
        'Submission Date': dayjs().utc().tz('America/Chicago').format('MM-DD-YYYY'),
        'Candidate Name': row.candidate.name,
        'Site Location': row.siteLocation,
        Date: row.startDate.format('MM-DD-YYYY'),
        'Start Time': row.startTime.format('h:mm A'),
        'End Time': row.endTime.format('h:mm A'),
        Lunch: row.lunch,
        'Total Hours': row.totalHours,
        Notes: row.notes,
        'Submitted By': userData.name,
        'Submitter ID': userData.id,
      };
    });
    try {
      setSubmitLoading(true);
      const response = await fetch(`${BASE_API}/create_timesheet`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
      });

      if (response.ok) {
        setDialogueOpen(false);
        enqueueSnackbar('Your timesheet has been successfully submitted', {
          variant: 'success',
        });
        setRows([getInitialEmptyData()]);
        localStorage.removeItem('saved_timesheet');
        setSubmitLoading(false);
      } else {
        throw new Error('An error occurred while submitting the timesheet.');
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.message ?? 'An error occurred during the network request',
        {
          variant: 'error',
        }
      );
    }
  };

  const handleAddRow = () => {
    setRows([...rows, getInitialEmptyData()]);
  };

  const handleRemoveRow = useCallback(
    (index: number): void => {
      if (index > -1 && index < rows.length) {
        const updatedRows = [...rows.slice(0, index), ...rows.slice(index + 1)];
        setRows(updatedRows);
      }
    },
    [rows]
  );

  const handleChange = useCallback(
    (
      index: number,
      field: keyof IRowData,
      value: any,
      candidateField?: keyof ICandidate
    ) => {
      setRows((prevState) => {
        const updatedRows = [...prevState];
        if (field === 'siteLocation') {
          updatedRows[index].siteLocation = value;
          updatedRows[index].candidate = {
            name: '',
            inputValue: '',
          };
        } else if (field === 'candidate') {
          updatedRows[index].candidate = {
            ...prevState[index].candidate,
            [candidateField ?? 'name']: value,
          };
        } else if (
          field === 'startTime' ||
          field === 'endTime' ||
          field === 'lunch'
        ) {
          updatedRows[index][field] = value;
          const diffHours =
            updatedRows[index].endTime.diff(
              updatedRows[index].startTime,
              'minute'
            ) /
              60 -
            updatedRows[index].lunch;
          const roundedHours = Number(diffHours.toFixed(2));
          updatedRows[index].totalHours = roundedHours;
        } else {
          updatedRows[index] = {
            ...prevState[index],
            [field]: value,
          };
        }
        return updatedRows;
      });
    },
    []
  );

  const getDialogueConfirmationMessage = () => {
    const hoursByCandidate: any = {};
    rows.forEach((entry) => {
      const { candidate, totalHours } = entry;
      const candidateName = candidate.name;
      if (!hoursByCandidate.hasOwnProperty(candidateName)) {
        hoursByCandidate[candidateName] = 0;
      }
      hoursByCandidate[candidateName] += totalHours;
    });

    let hoursString = ``;

    Object.entries(hoursByCandidate).forEach(([candidateName, totalHours]) => {
      hoursString += `${candidateName} has worked ${totalHours} hours\n`;
    });

    setContentMessage(hoursString);
  };

  const handleOpenConfirmationDialogue = () => {
    if (handleValidate()) {
      getDialogueConfirmationMessage();
      setDialogueOpen(true);
    }
  };

  const handleSaveTimesheet = () => {
    try {
      localStorage.setItem(
        'saved_timesheet',
        JSON.stringify({ timesheet: rows, currentUserId: userData.id })
      );
      enqueueSnackbar('Your timesheet has been successfully saved', {
        variant: 'success',
      });
    } catch (_) {
      enqueueSnackbar('Something went wrong, please try again', {
        variant: 'error',
      });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Navbar />
      <Box padding={2}>
        <TableContainer component={Paper}>
          <Box padding={2}>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  {theads.map((thead) => (
                    <TableCell align="center" key={thead}>
                      {thead}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow key={row.id} sx={{ alignItems: 'center' }}>
                    <TableCell align="center" sx={w200}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Location"
                        value={row.siteLocation}
                        onChange={(e) =>
                          handleChange(index, 'siteLocation', e.target.value)
                        }
                        select
                        error={!!errors[index]?.siteLocation}
                      >
                        {(locationData?.['Work Site Locations'] ?? []).map(
                          (location, index) => (
                            <MenuItem key={index} value={location}>
                              {location}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </TableCell>
                    <TableCell align="left" sx={w200}>
                      <Autocomplete
                        disabled={!row.siteLocation}
                        size="small"
                        disablePortal
                        id="combo-box-demo"
                        isOptionEqualToValue={(option, value) => {
                          return option === value || !option || !value;
                        }}
                        options={
                          locationData?.Contractors?.filter(
                            (contractor) =>
                              contractor['Work Site Location'] ===
                              row.siteLocation
                          ).map(
                            (contractor) => contractor?.['Contractor Full Name']
                          ).sort() ?? []
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Candidate"
                            error={!!errors[index]?.candidateName}
                          />
                        )}
                        value={row.candidate.name ?? ''}
                        onChange={(_, newValue: string | null) => {
                          handleChange(index, 'candidate', newValue, 'name');
                        }}
                        inputValue={row.candidate.inputValue}
                        onInputChange={(_, newInputValue) => {
                          handleChange(
                            index,
                            'candidate',
                            newInputValue,
                            'inputValue'
                          );
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <DatePicker
                        label="Start date"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors[index]?.startDate,
                          },
                        }}
                        value={row.startDate}
                        onChange={(newValue) =>
                          handleChange(index, 'startDate', newValue)
                        }
                      />
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <TimePicker
                        label="Start time"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors[index]?.startTime,
                          },
                        }}
                        value={row.startTime}
                        onChange={(newValue) =>
                          handleChange(index, 'startTime', newValue)
                        }
                      />
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <TimePicker
                        label="End time"
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors[index]?.endTime,
                          },
                        }}
                        value={row.endTime}
                        onChange={(newValue) =>
                          handleChange(index, 'endTime', newValue)
                        }
                      />
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <TextField
                        size="small"
                        label="Lunch"
                        select
                        fullWidth
                        value={row.lunch}
                        onChange={(e) =>
                          handleChange(index, 'lunch', e.target.value)
                        }
                      >
                        {lunchOptions.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <TextField
                        size="small"
                        type="number"
                        label="Total hours"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={row.totalHours}
                        error={!!errors[index]?.totalHours}
                      />
                    </TableCell>
                    <TableCell align="center" sx={w200}>
                      <TextField
                        size="small"
                        label="Notes"
                        value={row.notes}
                        onChange={(e) =>
                          handleChange(index, 'notes', e.target.value)
                        }
                      />
                    </TableCell>
                    {index !== 0 ? (
                      <TableCell sx={{ width: 3, padding: 0 }} align="right">
                        <Tooltip title="Delete the row">
                          <IconButton
                            sx={{ mt: 1 }}
                            onClick={() => handleRemoveRow(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" sx={w200} onClick={handleAddRow}>
            Add Row
          </Button>
          <Button
            variant="contained"
            color="info"
            sx={w200}
            onClick={handleSaveTimesheet}
          >
            Save template
          </Button>
          <Button
            variant="contained"
            sx={w200}
            onClick={handleOpenConfirmationDialogue}
            color="success"
          >
            Submit
          </Button>
        </Box>
      </Box>
      <CustomDialog
        open={dialogueOpen}
        setDialogueOpen={setDialogueOpen}
        headerMessage="Please confirm the weekly hours worked by each candidate"
        handleConfirm={handleConfirm}
        content={contentMessage}
        submitLoading={submitLoading}
      />
    </>
  );
};