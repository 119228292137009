import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleHomeNavigate = () => {
    navigate('/');
  };
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: 'black',
      }}
    >
      <Typography variant="h1" style={{ color: 'white' }}>
        404
      </Typography>
      <Typography variant="h6" style={{ color: 'white' }}>
        The page you're looking for doesn't exist.
      </Typography>
      <Button
        sx={{ marginTop: 10 }}
        variant="contained"
        color="warning"
        onClick={handleHomeNavigate}
      >
        Back Home
      </Button>
    </Box>
  );
};
