import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import { Link } from 'react-router-dom';
import {
  FIELD_IS_REQUIRED,
  INPUT_LETTERS_ONLY,
  NOT_CORRECT_EMAIL,
  NOT_MATCHING_PASSWORDS,
  SHORT_PASSWORD,
} from '../constants/errorMessages';
import { containsOnlyLetters, isEmail } from '../utils/inputValidation';
import { BASE_API } from '../constants/api';
import { useSnackbar } from 'notistack';

interface ISignupData {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const SignUp = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [signupData, setSignupData] = React.useState<ISignupData>({
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
  });
  const [validationErrors, setValidationErrors] = React.useState<
    Partial<ISignupData>
  >({});

  const handleValidation = () => {
    let isValid = true;
    let newErrors: Partial<ISignupData> = {};

    if (signupData.name === '') {
      isValid = false;
      newErrors.name = FIELD_IS_REQUIRED;
    } else if (!containsOnlyLetters(signupData.name)) {
      isValid = false;
      newErrors.name = INPUT_LETTERS_ONLY;
    }
    if (signupData.email === '') {
      isValid = false;
      newErrors.email = FIELD_IS_REQUIRED;
    } else if (!isEmail(signupData.email)) {
      isValid = false;
      newErrors.email = NOT_CORRECT_EMAIL;
    }
    if (signupData.password === '') {
      isValid = false;
      newErrors.password = FIELD_IS_REQUIRED;
    } else if (signupData.password.length < 6) {
      isValid = false;
      newErrors.password = SHORT_PASSWORD;
    }
    if (signupData.repeatPassword === '') {
      isValid = false;
      newErrors.repeatPassword = FIELD_IS_REQUIRED;
    } else if (signupData.repeatPassword.length < 6) {
      isValid = false;
      newErrors.repeatPassword = SHORT_PASSWORD;
    } else if (signupData.password !== signupData.repeatPassword) {
      isValid = false;
      newErrors.password = NOT_MATCHING_PASSWORDS;
      newErrors.repeatPassword = NOT_MATCHING_PASSWORDS;
    }
    setValidationErrors(newErrors);

    return isValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setSignupData((prevData: ISignupData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (handleValidation()) {
      const submitData = {
        email: signupData.email,
        password: signupData.password,
        name: signupData.name,
        id: crypto.randomUUID(),
      };
      try {
        const response = await fetch(`${BASE_API}/signup`, {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json',
          },
          redirect: 'follow',
          referrerPolicy: 'no-referrer',
          body: JSON.stringify(submitData),
        });

        if (response.ok) {
          enqueueSnackbar('User has been succesfully added', {
            variant: 'success',
          });
          setSignupData({
            name: '',
            email: '',
            password: '',
            repeatPassword: '',
          });
        } else {
          const errorData = await response.json();
          enqueueSnackbar(
            errorData.error ?? 'User with current email already exists',
            { variant: 'error' }
          );
        }
      } catch (error: any) {
        enqueueSnackbar(error.message ?? 'An error occurred during signup', {
          variant: 'error',
        });
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Full name"
                name="name"
                autoComplete="family-name"
                onChange={handleChange}
                value={signupData.name}
                error={!!validationErrors.name}
                helperText={validationErrors.name}
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={handleChange}
                value={signupData.email}
                error={!!validationErrors.email}
                helperText={validationErrors.email}
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                autoComplete="new-password"
                onChange={handleChange}
                value={signupData.password}
                error={!!validationErrors.password}
                helperText={validationErrors.password}
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="repeatPassword"
                label="Repeat password"
                type="password"
                autoComplete="new-password"
                onChange={handleChange}
                value={signupData.repeatPassword}
                error={!!validationErrors.repeatPassword}
                helperText={validationErrors.repeatPassword}
                sx={{
                  '& .MuiFormHelperText-root': {
                    marginLeft: 0,
                  },
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign Up
          </Button>
          <Grid container>
            <Grid item>
              <Link to="/login">Already have an account? Sign in</Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};
