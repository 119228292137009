import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { getUserData, removeUserData } from '../utils/userData';

export const Navbar = () => {
  const navigate = useNavigate();
  const userData = getUserData();

  const { pathname } = useLocation();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logout = () => {
    handleCloseUserMenu();
    removeUserData();
    navigate('/login');
  };

  if (pathname === '/login' || pathname === '/register') {
    return null;
  }

  return (
    <AppBar position="static" component="nav">
      <Box sx={{ flexGrow: 1, display: 'flex' }}>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, margin: 'auto', marginLeft: '2rem' }}
        >
          {`Timesheet of ${userData.name}`}
        </Typography>
        <Box sx={{ my: 'auto', ml: 'auto', alignItems: 'center' }}>
          {/* <ModeToggler /> */}
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenUserMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Tooltip title="Logout">
            <Menu
              id="menu-appbar"
              anchorEl={anchorElUser}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Tooltip>
        </Box>
      </Box>
    </AppBar>
  );
};
