import { Route, Routes } from 'react-router-dom';
import { Timesheet } from './components/TimeSheet/Timesheet';
import { Login } from './components/Login';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import { SignUp } from './components/Signup';
import { NotFoundPage } from './components/NotFoundPage';

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Timesheet />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default App;
