export const isEmail = (input: string): boolean => {
  // Regular expression to match email pattern
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailPattern.test(input);
};

export const containsOnlyLetters = (input: string): boolean => {
  const regex = /^[A-Za-z\s]+$/;
  return regex.test(input);
};
